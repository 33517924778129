import React, {
  useMemo,
  useState,
  useEffect,
  Fragment,
  useCallback
} from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { FeatureFlag } from '__types__';
import { RestrictedAccess } from 'lib/restrictedAccess';
import { BOOKING_ACTIONS } from 'store/v1/bookings/bookings.constants.js';
import { TASK_LISTS_ACTIONS } from 'store/task_lists/task_lists.constants.js';
import {
  getBooking,
  updateBooking
} from 'store/v1/bookings/bookings.actions.js';
import { displayAlert, openModal } from 'store/v1/ui/ui.actions.js';
import { getBookingExpenses } from 'store/v1/expenses/expenses.actions.js';

import SidebarDraw from 'v1/components/frame/SidebarDraw/SidebarDraw';
import BookingSidebarDrawerHeader from './BookingSidebarDrawerHeader/BookingSidebarDrawerHeader';
import FormBuilder from 'v1/components/FormBuilder/FormBuilder';
import BookingInvoiceManagement from 'v1/components/feature/BookingComponents/Booking/BookingInvoiceManagement/BookingInvoiceManagement';
import {
  ExpenseReceiptManagement,
  Grid,
  GridCell,
  Label,
  Loading,
  PageTabs
} from 'v1/components/shared';
import TaskList from 'features/Tasks/TaskList/TaskList';

import useEvent from 'v1/helpers/hooks/useEvent';
import { getBookingError } from 'v1/helpers/bookingHelper';

import { chain } from 'lodash';
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';

import './BookingSidebarDrawer.scss';
import get from 'lodash/get';
import ValidationErrorLevel from 'lib/errors/ValidationErrorLevel';
import useSocketEvent from 'hooks/useSocketEvent';
import { SOCKET_EVENTS } from 'lib/websockets';

// NOTE: if we ever change the implementation of sidebar drawers to not be rendered
// silently off to one side in pepetuity, then we need to watch for how these modals are called
// E.g. in the resources calendar, a booking conflict triggers a modal which is called from BookingSidebarDrawer

const BookingSidebarDrawer = ({
  isOpen,
  booking: loadedBooking,
  closeSidebar,
  options = {}
}) => {
  const dispatch = useDispatch();
  const bookingTypes = useSelector(state => state.booking_types);
  const bookings = useSelector(state => state.bookings);
  const contacts = useSelector(state => state.contacts);
  const expenses = useSelector(state => state.expenses);
  const productions = useSelector(state => state.productions);

  const [sidebarHasOpened, setSidebarHasOpened] = useState(false);
  const [activeContainer, setActiveContainer] = useState('details');
  const [booking, setBooking] = useState(
    _get(bookings, ['data', loadedBooking.id], {})
  );

  const bookingExpenses = _filter(
    _get(expenses, 'data'),
    e => e.booking_id === booking.id
  );

  const defaultBookingType = chain(bookingTypes.data)
    .filter(b => !b.archived)
    .value();
  const bookingTypeId = _get(defaultBookingType, [0, 'id']);

  const bookingError = getBookingError(booking, productions.data);

  useEffect(() => {
    if (loadedBooking.id && sidebarHasOpened) {
      setBooking(_get(bookings, ['data', loadedBooking.id], {}));
      dispatch(getBookingExpenses([loadedBooking.id]));
    }
  }, [loadedBooking.id, sidebarHasOpened]);

  useEffect(() => {
    setActiveContainer(options.initialTab || 'details');
  }, [options]);

  // TODO: this should maybe be extracted somewhere, as we've got the same logic in a couple of places where we're able to update booking dates
  useEvent(
    [
      BOOKING_ACTIONS.UPDATE_BOOKING,
      TASK_LISTS_ACTIONS.ASSIGN_TASK_LIST_TO_BOOKING
    ],
    {
      onSuccess: () => {
        setBooking(_get(bookings, ['data', loadedBooking.id], {}));
        dispatch(displayAlert('success', 'Booking Updated'));
      },
      onFailure: () => {
        setBooking(_get(bookings, ['data', loadedBooking.id], {}));
        if (_get(bookings, ['error', 'status']) === 409) {
          return dispatch(
            openModal('BookingConflictModal', {
              method: 'UPDATE',
              resourceId: get(booking, 'contact_id')
            })
          );
        }
        dispatch(displayAlert('error', 'Booking update failed'));
      }
    }
  );

  const bookingFields = useMemo(() => {
    return [
      { name: 'contact_id', type: 'CORE_FIELD', active: true },
      { name: 'expenses', type: 'CORE_FIELD', active: true },
      { name: 'events', type: 'CORE_FIELD', active: true },
      { name: 'resource_slot_assignments', type: 'CORE_FIELD', active: true },
      { name: 'status_id', type: 'CORE_FIELD', active: true },
      ..._get(bookingTypes, `data.${bookingTypeId}.metastructure.fields`, [])
    ];
  }, [bookingTypes, bookingTypeId]);

  const handleSubmit = useCallback(
    debounce(updatedBooking => {
      dispatch(updateBooking(updatedBooking.id, updatedBooking));
    }, 200),
    []
  );

  function onBookingUpdate(updatedBooking = {}) {
    updatedBooking = {
      ...updatedBooking,
      booking_type_id: booking.booking_type_id || bookingTypeId, // TODO: Needs cleaning up, we shouldn't have to check each time if this exists.
      rate_interval: updatedBooking.rate_interval || 'DAILY' // TODO: Needs cleaning up, we shouldn't have to check each time if this exists.
    };
    setBooking(updatedBooking);
    handleSubmit(updatedBooking);
  }

  const renderContent = (renderCoreField, renderCustomFields) => {
    switch (activeContainer) {
      case 'details':
        return (
          <Fragment>
            {bookingError &&
              bookingError.level === ValidationErrorLevel.CRITICAL && (
                <Grid className="SidebarDrawer-section error">
                  <GridCell width="initial">
                    <img src="/images/icon-warning.svg" width="10px" alt="" />
                  </GridCell>
                  <GridCell width="initial">
                    There are some issues with booking dates - please see below:
                  </GridCell>
                </Grid>
              )}
            <Grid className="SidebarDrawer-section">
              <GridCell width="2/12">
                <Label size="L">Resource</Label>
              </GridCell>
              <GridCell>{renderCoreField('contact_id')}</GridCell>
            </Grid>
            <Grid className="SidebarDrawer-section">
              <GridCell width="2/12">
                <Label size="L">Booking Dates</Label>
              </GridCell>
              <GridCell>
                {renderCoreField('events', {
                  eventType: 'BOOKING'
                })}
              </GridCell>
            </Grid>
            <Grid className="SidebarDrawer-section">
              <GridCell>
                <Label size="L">Assignments</Label>
                {renderCoreField('resource_slot_assignments')}
              </GridCell>
            </Grid>
            {/* <RestrictedAccess flag={FeatureFlag.TASKS}>
              <Grid className="SidebarDrawer-section">
                <GridCell width="2/12">
                  <Label size="L">Tasks</Label>
                </GridCell>
                <GridCell>
                  <TaskList
                    taskListId={booking.tasklist_id}
                    bookingId={booking.id}
                    resourceId={booking.contact_id}
                  />
                </GridCell>
              </Grid>
            </RestrictedAccess> */}
            <Grid className="SidebarDrawer-section" gutters="M">
              <GridCell width="2/12">
                <Label size="L">Rate</Label>
              </GridCell>
              <GridCell>{renderCoreField('rate')}</GridCell>
            </Grid>
            <Grid className="SidebarDrawer-section">
              <GridCell width="2/12">
                <Label size="L">Expenses</Label>
              </GridCell>
              <GridCell>{renderCoreField('expenses')}</GridCell>
            </Grid>
            <Grid className="SidebarDrawer-section">
              <GridCell width="2/12">
                <Label size="L">Contracts</Label>
              </GridCell>
              <GridCell>{renderCoreField('contracts')}</GridCell>
            </Grid>
            <Grid className="SidebarDrawer-section">
              <GridCell width="2/12">
                <Label size="L">Note</Label>
              </GridCell>
              <GridCell>{renderCoreField('note', { label: null })}</GridCell>
            </Grid>
            <Grid className="SidebarDrawer-section">
              <GridCell width="2/12">
                <Label size="L">Additional</Label>
              </GridCell>
              <GridCell>{renderCustomFields()}</GridCell>
            </Grid>
          </Fragment>
        );
      case 'invoices':
        return (
          <div className="BookingDocuments inset-L">
            <div className="stack-L">
              <BookingInvoiceManagement
                bookingId={booking.id}
                resourceId={booking.contact_id}
              />
            </div>
            {!_isEmpty(bookingExpenses) && (
              <div className="stack-L">
                <h3 className="stack-M">Expense Receipts</h3>
                {bookingExpenses &&
                  bookingExpenses
                    .filter(e => !e.archived)
                    .map(expense => (
                      <ExpenseReceiptManagement expenseId={expense.id} />
                    ))}
              </div>
            )}
          </div>
        );
      default:
        break;
    }
  };
  return (
    <SidebarDraw
      className="BookingSidebarDrawer"
      isOpen={isOpen}
      closeSidebar={closeSidebar}
      sidebarHasOpened={() => setSidebarHasOpened(true)}
      size="wide"
    >
      {!_isEmpty(booking) ? (
        <div className="SidebarDrawer-inner">
          <FormBuilder
            autoSave
            key={booking.id}
            data={{
              ...booking,
              contact: _get(
                contacts,
                ['data', booking.contact_id],
                _get(booking, 'contact')
              )
            }}
            fields={bookingFields}
            onSubmit={onBookingUpdate}
          >
            {({ renderCoreField, renderCustomFields }) => (
              <>
                {sidebarHasOpened && (
                  <BookingSidebarDrawerHeader
                    bookingId={booking.id}
                    renderCoreField={renderCoreField}
                    ownerId={booking.user_id}
                    onOwnerChange={id =>
                      onBookingUpdate({ ...booking, user_id: id })
                    }
                  />
                )}
                <PageTabs
                  className="BookingSidebarDrawer-tabs"
                  tabs={[
                    {
                      text: 'Details',
                      container: 'details'
                    },
                    {
                      text: 'Invoices / Receipts',
                      container: 'invoices'
                    }
                  ]}
                  onTabSelect={container => setActiveContainer(container)}
                  activeContainer={activeContainer}
                  borderBottom
                />
                {sidebarHasOpened &&
                  renderContent(renderCoreField, renderCustomFields)}
              </>
            )}
          </FormBuilder>
        </div>
      ) : (
        <Loading />
      )}
    </SidebarDraw>
  );
};

BookingSidebarDrawer.propTypes = {
  isOpen: PropTypes.bool,
  booking: PropTypes.object, // TODO: shape
  closeSidebar: PropTypes.func
};

function SelfContainerBookingSidebarDrawer(props) {
  const dispatch = useDispatch();

  const bookingFromStore = useSelector(
    state => state.bookings.data[props.booking.id]
  );

  useEffect(() => {
    if (props.booking.id !== undefined && props.booking.id !== null)
      dispatch(getBooking([props.booking.id]));
  }, [props.booking.id]);

  useSocketEvent(
    event => {
      if (
        event.type === 'BOOKING_ARCHIVED' &&
        _get(event, 'payload.id') === props.booking.id
      ) {
        props.closeSidebar();
      }
    },
    [SOCKET_EVENTS.BOOKING_ARCHIVED]
  );

  if (!bookingFromStore) {
    return (
      <SidebarDraw
        className="BookingSidebarDrawer"
        isOpen={props.isOpen}
        size="wide"
        closeSidebar={props.closeSidebar}
      >
        <Loading />
      </SidebarDraw>
    );
  }

  return <BookingSidebarDrawer {...props} />;
}

export default SelfContainerBookingSidebarDrawer;
