import React, { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import './ProductionSidebarDrawer.scss';

import { makeGetProductionFields } from 'store/v1/custom_fields/custom_fields.selectors.js';
import SidebarDraw from 'v1/components/frame/SidebarDraw/SidebarDraw';
import ProductionSidebarDrawerHeader from './ProductionSidebarDrawerHeader/ProductionSidebarDrawerHeader';
import ProductionSidebarDrawerTabs from './ProductionSidebarDrawerTabs/ProductionSidebarDrawerTabs';
import FormBuilder from 'v1/components/FormBuilder/FormBuilder';
import { Grid, GridCell, Label, PressStud } from 'v1/components/shared';

import useEvent from 'v1/helpers/hooks/useEvent';
import useProductionRelations from 'v1/helpers/hooks/useProductionRelations';
import { PRODUCTIONS_ACTIONS } from 'store/v1/productions/productions.constants.js';
import { updateProduction } from 'store/v1/productions/productions.actions.js';
import {
  getProductionAssociations,
  getProduction
} from 'store/v1/productions/productions.actions.js';
import { getTags, selectTagsStore, selectTagsList } from 'store/v1/tags';
import { displayAlert, closeModal } from 'store/v1/ui/ui.actions.js';
import _get from 'lodash/get';
import moment from 'moment';

const ProductionSidebarDrawer = ({
  isOpen,
  production: loadedProduction,
  options,
  closeSidebar
}) => {
  const dispatch = useDispatch();

  const productions = useSelector(state => state.productions);
  const tagsStore = useSelector(selectTagsStore);
  const suggestions = useSelector(selectTagsList);

  const production = _get(productions, ['data', loadedProduction.id]);

  const getFields = useMemo(makeGetProductionFields, []);
  const fields = useSelector(state =>
    getFields(state, {
      typeId: production ? production.production_type_id : null
    })
  );

  useEffect(() => {
    if (production && production.id) {
      if (!productions.associations_data[production.id])
        dispatch(getProductionAssociations(production.id));
    }
    if (!tagsStore.fetched) dispatch(getTags());
    if (!production && loadedProduction.id) {
      dispatch(getProduction(loadedProduction.id));
    }
  }, [production, loadedProduction]);

  const onProductionUpdate = values => {
    dispatch(updateProduction(production.id, values));
  };

  useEvent(
    [
      PRODUCTIONS_ACTIONS.UPDATE_PRODUCTION,
      PRODUCTIONS_ACTIONS.UPDATE_RESOURCE_SLOT
    ],
    {
      onSuccess: () => {
        dispatch(displayAlert('success', 'Production Updated'));
      },
      onFailure: () => dispatch(displayAlert('error', 'Failed to update'))
    }
  );
  useEvent(PRODUCTIONS_ACTIONS.DELETE_PRODUCTION, {
    onSuccess: () => dispatch(closeModal())
  });

  useProductionRelations();

  return (
    <SidebarDraw
      className="ProductionSidebarDrawer"
      isOpen={isOpen}
      closeSidebar={closeSidebar}
      borderColor={production && production.color}
      size="wide"
      // closeOnClickOutside={!ui.modalIsOpen}
    >
      {production && (
        <div className="SidebarDrawer-inner">
          <FormBuilder
            autoSave
            key={production.id}
            data={production}
            fields={[
              { name: 'tags', type: 'CORE_FIELD', active: true },
              ...fields
            ]}
            onSubmit={onProductionUpdate}
          >
            {({ renderCoreField, renderCustomFields }) => (
              <>
                <ProductionSidebarDrawerHeader
                  production={production}
                  renderCoreField={renderCoreField}
                />
                <Grid className="SidebarDrawer-section">
                  <GridCell width="2/12">
                    <Label size="L">Dates</Label>
                  </GridCell>
                  <GridCell>
                    {renderCoreField('production_date', {
                      eventType: 'PRODUCTION'
                    })}
                  </GridCell>
                </Grid>

                <Grid className="SidebarDrawer-section">
                  <GridCell width="2/12">
                    <Label size="L">Tags</Label>
                  </GridCell>
                  <GridCell>
                    {renderCoreField('tags', { suggestions, fetchTags: false })}
                  </GridCell>
                </Grid>

                {fields.find(
                  field =>
                    field.type === 'CUSTOM_FIELD' ||
                    field.type === 'CUSTOM_FIELD_GROUP'
                ) && (
                  <Grid className="SidebarDrawer-section">
                    <GridCell width="2/12">
                      <Label size="L">Additional</Label>
                    </GridCell>
                    <GridCell>
                      {renderCustomFields({
                        width: '1/3',
                        appearance: 'underline',
                        size: 'S'
                      })}
                    </GridCell>
                  </Grid>
                )}

                <Grid className="SidebarDrawer-section">
                  <GridCell width="2/12">
                    <Label size="L"> </Label>
                  </GridCell>
                  <GridCell>
                    <PressStud
                      label="Update Production"
                      size="S"
                      isLoading={productions.loading === 'UPDATE_PRODUCTION'}
                      action={onProductionUpdate}
                    />
                  </GridCell>
                </Grid>
              </>
            )}
          </FormBuilder>
          <ProductionSidebarDrawerTabs
            production={production}
            options={options}
          />
          {production.id && (
            <div className="ProductionSidebarDrawer-meta">
              <span
                title={moment(production.updated_at).format(
                  'MM/DD/YYYY HH:mm:ss'
                )}
              >
                Updated {moment(production.updated_at).fromNow()}
              </span>
              {' · '}
              <span
                title={moment(production.created_at).format(
                  'MM/DD/YYYY HH:mm:ss'
                )}
              >
                Created {moment(production.created_at).fromNow()}
              </span>
            </div>
          )}
        </div>
      )}
    </SidebarDraw>
  );
};

ProductionSidebarDrawer.propTypes = {
  isOpen: PropTypes.bool,
  production: PropTypes.object, // TODO: shape
  options: PropTypes.object, // TODO: shape
  closeSidebar: PropTypes.func
};

export default ProductionSidebarDrawer;
